<template>
  <!-- eslint-disable -->
  <div class="forecast-section">
    Comparable Properties
  </div>
  <!-- eslint-enable -->
</template>

<script>
  export default {
    name: 'ComparableProperties',
    components: {
    },
    props: {
    },
    data: () => ({
    }),
    computed: {
    },
    mounted () {
    },
  }
</script>
